<script>
import appConfig from "@/app.config";
import CKEditor from "@ckeditor/ckeditor5-vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

import { required } from "vuelidate/lib/validators";

/**
 * Form Frontend component
 */
export default {
  page: {
    title: "Formulário do Site",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { ckeditor: CKEditor.component },
  data() {
    return {
      editor: ClassicEditor,      
      headerText: "Aqui você poderá colocar o pixel do facebook e as tags do Google. Lembrando que você deverá pegar o código em HTML (Esse código ficará no início da página)",
      footerText: "Aqui você pode colocar código de terceiros para executar dentro do site. Geralmente no footer é inserido códigos JS (Esse código ficará no final da página)",
      form: {},
      submitted: false,
      submitform: false,
      submit: false,
      typesubmit: false,
    };
  },
  validations: {
    form: {
      name: { required },
      title: { required },
      slogan: { required },
      email: { required },
      times: { required },
    },
  },
  methods: {
    formSubmit() {
      this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch();
    },
  },
};
</script>

<template>
  <div>
    <h4 class="card-title mb-4">Configurações do Site</h4>
    <form class="needs-validation" @submit.prevent="formSubmit">
      <div class="row">
        <div class="col-lg-12">
          <div class="mb-3">
            <label class="form-label">Título do Banner</label>
            <input
              v-model="form.bannerTittle"
              type="text"
              class="form-control"
              placeholder="Insira o título do banner"
              name="banner-title"
            />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12">
          <div class="mb-3">
            <label class="form-label">Subtítulo do Banner</label>
            <input
              v-model="form.bannerSubtitle"
              type="text"
              class="form-control"
              placeholder="Insira o subtítulo do banner"
              name="banner-subtitle"
            />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12">
          <div class="mb-3">
            <label for="facebook">Link do Facebook</label>
            <input
              v-model="form.facebook"
              type="text"
              class="form-control"
              placeholder="Ex: https://www.facebook.com/exemplo"
              name="facebook"
            />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12">
          <div class="mb-3">
            <label for="instagram">Link do Instagram</label>
            <input
              v-model="form.instagram"
              type="text"
              class="form-control"
              placeholder="Ex: https://www.instagram.com/exemplo"
              name="instagram"
            />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12">
          <div class="mb-3">
            <label for="linkedin">Link do Linkedin</label>
            <input
              v-model="form.linkedin"
              type="text"
              class="form-control"
              placeholder="Ex: https://www.linkedin.com/company/exemplo"
              name="linkedin"
            />
          </div>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-lg-12">
          <label class="col-md-3 form-label">
            Header
            <i class="fas fa-info-circle" v-b-tooltip.hover :title="headerText">
            </i>
          </label>
          <textarea
            v-model="form.header"
            rows="7"
            class="form-control"
            name="description"
            placeholder="Insira o Header do site"
          ></textarea>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-lg-12">
          <label class="col-md-3 form-label">
            Footer
            <i class="fas fa-info-circle" v-b-tooltip.hover :title="footerText">
            </i>
          </label>
          <textarea
            v-model="form.footer"
            rows="7"
            class="form-control"
            name="description"
            placeholder="Insira o Footer do site"
          ></textarea>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-lg-12">
          <div class="form-group">
            <label for="cookie_status"
              >Status do Cookie <span class="required">*</span></label
            ><br />
            <input
              type="radio"
              value="active"
              name="cookie_status"
              checked=""
            />
            Ativo
            <input
              type="radio"
              style="margin-left: 10px"
              value="inactive"
              name="cookie_status"
            />
            Inativo
          </div>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-lg-12">
          <div class="form-group">
            <label for="cookie_status"
              >Tradução <span class="required">*</span></label
            ><br />
            <input type="radio" value="active" name="traduction" checked="" />
            Ativo
            <input
              type="radio"
              style="margin-left: 10px"
              value="inactive"
              name="traduction"
            />
            Inativo
          </div>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-lg-12">
          <div class="form-group">
            <label for="cookie_status"
              >Status do Ecommerce <span class="required">*</span></label
            ><br />
            <input type="radio" value="active" name="e-status" checked="" />
            Ativo
            <input
              type="radio"
              style="margin-left: 10px"
              value="inactive"
              name="e-status"
            />
            Inativo
          </div>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-lg-12">
          <div class="form-group">
            <label for="cookie_status"
              >Termos de Uso no Checkout <span class="required">*</span></label
            ><br />
            <input
              type="radio"
              value="active"
              name="checkout-terms"
              checked=""
            />
            Ativo
            <input
              type="radio"
              style="margin-left: 10px"
              value="inactive"
              name="checkout-terms"
            />
            Inativo
          </div>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-lg-12">
          <div class="form-group">
            <label for="cookie_status">Exibir Menu Cursos?</label><br />
            <input
              type="radio"
              value="active"
              name="show-courses-menu"
              checked=""
            />
            Sim
            <input
              type="radio"
              style="margin-left: 10px"
              value="inactive"
              name="show-courses-menu"
            />
            Não
          </div>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-lg-12">
          <div class="form-group">
            <label for="cookie_status"
              >Campo de Pesquisas no Banner
              <span class="required">*</span></label
            ><br />
            <input
              type="radio"
              value="active"
              name="banner-search"
              checked=""
            />
            Ativo
            <input
              type="radio"
              style="margin-left: 10px"
              value="inactive"
              name="banner-search"
            />
            Inativo
          </div>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-lg-12">
          <div class="form-group">
            <label for="cookie_status"
              >Seção "Escolha por Categoria"
              <span class="required">*</span></label
            ><br />
            <input
              type="radio"
              value="active"
              name="category-search"
              checked=""
            />
            Ativo
            <input
              type="radio"
              style="margin-left: 10px"
              value="inactive"
              name="category-search"
            />
            Inativo
          </div>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-lg-12">
          <div class="form-group">
            <label for="cookie_status"
              >Seção "Melhores Cursos" <span class="required">*</span></label
            ><br />
            <input
              type="radio"
              value="active"
              name="best-courses-search"
              checked=""
            />
            Ativo
            <input
              type="radio"
              style="margin-left: 10px"
              value="inactive"
              name="best-courses-search"
            />
            Inativo
          </div>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-lg-12">
          <div class="form-group">
            <label for="cookie_status"
              >Seção "Cursos Mais Vendidos"
              <span class="required">*</span></label
            ><br />
            <input
              type="radio"
              value="active"
              name="best-sellers-courses-search"
              checked=""
            />
            Ativo
            <input
              type="radio"
              style="margin-left: 10px"
              value="inactive"
              name="best-sellers-courses-search"
            />
            Inativo
          </div>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-lg-12">
          <div class="form-group">
            <label for="cookie_status"
              >Seção "Funcionalidades" <span class="required">*</span></label
            ><br />
            <input
              type="radio"
              value="active"
              name="functionalities"
              checked=""
            />
            Ativo
            <input
              type="radio"
              style="margin-left: 10px"
              value="inactive"
              name="functionalities"
            />
            Inativo
          </div>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-lg-12">
          <div class="form-group">
            <label for="cookie_status"
              >Seção "Planos" <span class="required">*</span></label
            ><br />
            <input type="radio" value="active" name="plans" checked="" /> Ativo
            <input
              type="radio"
              style="margin-left: 10px"
              value="plans"
              name="plans"
            />
            Inativo
          </div>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-lg-12">
          <label class="col-md-3 form-label"> Cookie Note </label>
          <textarea
            v-model="form.header"
            rows="7"
            class="form-control"
            name="description"
            placeholder="Insira as Notas de Cookies"
          ></textarea>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-lg-12">
          <label class="col-md-3 form-label"> CTA Abaixo do Header </label>
          <ckeditor v-model="form.ctaHeader" :editor="editor"></ckeditor>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-lg-12">
          <label class="col-md-3 form-label"> Política de Cookies </label>
          <ckeditor v-model="form.cookiesPolicy" :editor="editor"></ckeditor>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-lg-12">
          <label class="col-md-3 form-label"> Sobre Nós </label>
          <ckeditor v-model="form.aboutUs" :editor="editor"></ckeditor>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-lg-12">
          <label class="col-md-3 form-label"> Termos e Condições </label>
          <ckeditor v-model="form.terms" :editor="editor"></ckeditor>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-lg-12">
          <label class="col-md-3 form-label"> Política de Privacidade </label>
          <ckeditor v-model="form.privacyPolicy" :editor="editor"></ckeditor>
        </div>
      </div>
      <div class="row justify-content-center mb-5">
        <div class="col-md-4">
          <button type="button" class="btn btn-primary btn-block w-100 p-3">
            Atualizar Configurações
          </button>
        </div>
      </div>
    </form>
  </div>
</template>