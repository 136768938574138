<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";
import FormFrontend from "@/components/forms/form-frontend/index";
import FormRecaptcha from "@/components/forms/form-frontend/form-recaptcha/index";
import FormFrontendImages from "@/components/forms/form-frontend/form-frontend-images/index";
import appConfig from "@/app.config";

/**
 * Frontend Settings component
 */
export default {
  page: {
    title: "Configurações do Site",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader, FormFrontend, FormFrontendImages, FormRecaptcha },
  data() {
    return {
      title: "Configurações do Site",
      items: [
        {
          text: "Dashboard",
          href: "/",
        },
        {
          text: "Configurações do Site",
          active: true,
        },
      ],
    };
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" :enableCad="false" />
    <div class="row">
      <div class="col">
        <div class="card">
          <div class="card-body">
            <!-- Form Frontend -->
            <FormFrontend />

            <!-- Form Recaptcha -->
            <FormRecaptcha />

            <!-- Form Frontend Images -->
            <FormFrontendImages />
          </div>
        </div>
      </div>
    </div>
    <!-- end row -->
  </Layout>
</template>
