<script>
import appConfig from "@/app.config";

import { required } from "vuelidate/lib/validators";

/**
 * Form Recaptcha component
 */
export default {
  page: {
    title: "Formulário Recaptcha",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: {},
  data() {
    return {
      form: {},
      submitted: false,
      submitform: false,
      submit: false,
      typesubmit: false,
    };
  },
  validations: {
    form: {
      name: { required },
      title: { required },
      slogan: { required },
      email: { required },
      times: { required },
    },
  },
  methods: {
    formSubmit() {
      this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch();
    },
  },
};
</script>

<template>
  <div>
    <h4 class="card-title mb-4">Configurações de Recaptcha</h4>
    <form class="needs-validation" @submit.prevent="formSubmit">
      <div class="row mb-3">
        <div class="col-lg-12">
          <div class="form-group">
            <label for="cookie_status"
              >Status do Recaptcha <span class="required">*</span></label
            ><br />
            <input type="radio" value="active" name="recaptcha" checked="" />
            Ativo
            <input
              type="radio"
              style="margin-left: 10px"
              value="plans"
              name="recaptcha"
            />
            Inativo
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12">
          <div class="mb-3">
            <label for="instagram"
              >Recaptcha SiteKey (v2) <span class="required">*</span></label
            >
            <input
              v-model="form.sitekey"
              type="text"
              class="form-control"
              placeholder="Cole sua chave aqui"
              name="sitekey"
            />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12">
          <div class="mb-3">
            <label for="instagram"
              >Recaptcha SecretKey (v2) <span class="required">*</span></label
            >
            <input
              v-model="form.sitekey"
              type="text"
              class="form-control"
              placeholder="Cole sua chave aqui"
              name="sitekey"
            />
          </div>
        </div>
      </div>
      <div class="row justify-content-center mb-5">
        <div class="col-md-4">
          <button type="button" class="btn btn-primary btn-block w-100 p-3">
            Atualizar Configurações de Recaptcha
          </button>
        </div>
      </div>
    </form>
  </div>
</template>
