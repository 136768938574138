<script>
import appConfig from "@/app.config";

import { required } from "vuelidate/lib/validators";

/**
 * Form Frontend Images component
 */
export default {
  page: {
    title: "Formulário de Imagens do Site",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: {},
  data() {
    return {
      form: {},
      submitted: false,
      submitform: false,
      submit: false,
      typesubmit: false,
    };
  },
  validations: {
    form: {
      name: { required },
      title: { required },
      slogan: { required },
      email: { required },
      times: { required },
    },
  },
  methods: {
    formSubmit() {
      this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch();
    },
  },
};
</script>

<template>
  <div>
    <h4 class="card-title mb-4">Atualizar Imagens</h4>
    <div class="row justify-content-center">
        <div class="col-12 col-md-6">
            <div class="card">
                <div class="card-body">
                    <div class="col-xl-12">
                        <h4 class="mb-3 header-title">Atualizar Imagem do Banner</h4>                       
                        <div class="row justify-content-center">
                            <form class="text-center w-100" enctype="multipart/form-data">
                                <b-card no-body :img-src="require('@/assets/images/small/img-1.jpg')" class="mb-0" img-alt="Card image" img-top>
                                    <b-card-body class="mb-0" style="padding-bottom: 0px">
                                        <b-card-title>
                                            <h5 class="card-title">Card title</h5>
                                        </b-card-title>
                                        <b-card-text>                                        
                                            <div class="upload-options">
                                                <label for="banner_image" class="btn">
                                                    <i class="mdi mdi-camera"></i> Carregar Imagem de
                                                    Banner <br />
                                                    <small>(1920 X 639)</small>
                                                </label>
                                                <input
                                                    id="banner_image"
                                                    style="visibility: hidden"
                                                    type="file"
                                                    class="image-upload"
                                                    name="banner_image"
                                                    accept="image/*"
                                                />
                                            </div>
                                        </b-card-text>
                                    </b-card-body>
                                </b-card>
                                <button type="submit" class="btn btn-primary btn-block w-100">
                                    Carregar Imagem de Banner
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-12 col-md-6">
            <div class="card">
                <div class="card-body">
                    <div class="col-xl-12">
                        <h4 class="mb-3 header-title">
                            Atualizar Imagem do Banner Mobile
                        </h4>
                        <div class="row justify-content-center">
                            <form
                                class="text-center w-100"
                                enctype="multipart/form-data"
                            >
                                <b-card no-body :img-src="require('@/assets/images/small/img-1.jpg')" class="mb-0" img-alt="Card image" img-top>
                                    <b-card-body class="mb-0" style="padding-bottom: 0px">
                                        <b-card-title>
                                            <h5 class="card-title">Card title</h5>
                                        </b-card-title>
                                        <b-card-text>                                        
                                            <div class="upload-options">
                                                <label for="banner_image" class="btn">
                                                    <i class="mdi mdi-camera"></i> Carregar Imagem de
                                                    Banner Mobile <br />
                                                    <small>(460 X 700)</small>
                                                </label>
                                                <input
                                                    id="banner_image"
                                                    style="visibility: hidden"
                                                    type="file"
                                                    class="image-upload"
                                                    name="banner_image"
                                                    accept="image/*"
                                                />
                                            </div>
                                        </b-card-text>
                                    </b-card-body>
                                </b-card>
                                <button type="submit" class="btn btn-primary btn-block w-100">
                                    Carregar Imagem de Banner Mobile
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row justify-content-center">
        <div class="col-xl-4 col-lg-6">
            <div class="card">
                <div class="card-body">
                    <div class="col-xl-12">
                        <h4 class="mb-3 header-title">Atualizar Logotipo Claro</h4>
                        <div class="row justify-content-center">
                            <form
                                class="text-center"
                                enctype="multipart/form-data"
                            >
                                <b-card no-body :img-src="require('@/assets/images/small/img-1.jpg')" class="mb-0" img-alt="Card image" img-top>
                                    <b-card-body class="mb-0" style="padding-bottom: 0px">
                                        <b-card-title>
                                            <h5 class="card-title">Card title</h5>
                                        </b-card-title>
                                        <b-card-text>                                        
                                            <div class="upload-options">
                                                <label for="banner_image" class="btn">
                                                    <i class="mdi mdi-camera"></i> Carregar Logotipo
                                                    Claro <br />
                                                    <small>(829 X 296)</small>
                                                </label>
                                                <input
                                                    id="banner_image"
                                                    style="visibility: hidden"
                                                    type="file"
                                                    class="image-upload"
                                                    name="banner_image"
                                                    accept="image/*"
                                                />
                                            </div>
                                        </b-card-text>
                                    </b-card-body>
                                </b-card>                    
                                <button type="submit" class="btn btn-primary btn-block w-100">
                                    Carregar Logotipo Claro
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-xl-4 col-lg-6">
            <div class="card">
                <div class="card-body">
                    <div class="col-lg-12">
                        <h4 class="mb-3 header-title text-center">Atualizar Logotipo Escuro</h4>
                        <div class="row justify-content-center">
                            <form
                                class="text-center"
                                enctype="multipart/form-data"
                            >
                                <b-card no-body :img-src="require('@/assets/images/small/img-1.jpg')" class="mb-0" img-alt="Card image" img-top>
                                    <b-card-body class="mb-0" style="padding-bottom: 0px">
                                        <b-card-title>
                                            <h5 class="card-title">Card title</h5>
                                        </b-card-title>
                                        <b-card-text>                                        
                                            <div class="upload-options">
                                                <label for="banner_image" class="btn">
                                                    <i class="mdi mdi-camera"></i> Carregar Logotipo
                                                    Escuro <br />
                                                    <small>(829 X 296)</small>
                                                </label>
                                                <input
                                                    id="banner_image"
                                                    style="visibility: hidden"
                                                    type="file"
                                                    class="image-upload"
                                                    name="banner_image"
                                                    accept="image/*"
                                                />
                                            </div>
                                        </b-card-text>
                                    </b-card-body>
                                </b-card>                     
                                <button type="submit" class="btn btn-primary btn-block w-100">
                                    Carregar Logotipo Escuro
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-xl-4 col-lg-6">
            <div class="card">
                <div class="card-body">
                    <div class="col-lg-12">
                        <h4 class="mb-3 header-title text-center">Atualizar Logo Pequeno</h4>
                        <div class="row justify-content-center">
                            <form
                                class="text-center"
                                enctype="multipart/form-data"
                            >
                                <b-card no-body :img-src="require('@/assets/images/small/img-1.jpg')" class="mb-0" img-alt="Card image" img-top>
                                    <b-card-body class="mb-0" style="padding-bottom: 0px">
                                        <b-card-title>
                                            <h5 class="card-title">Card title</h5>
                                        </b-card-title>
                                        <b-card-text>                                        
                                            <div class="upload-options">
                                                <label for="banner_image" class="btn">
                                                    <i class="mdi mdi-camera"></i> Carregar logo pequeno
                                                    <br />
                                                    <small>(60 X 60)</small>
                                                </label>
                                                <input
                                                    id="banner_image"
                                                    style="visibility: hidden"
                                                    type="file"
                                                    class="image-upload"
                                                    name="banner_image"
                                                    accept="image/*"
                                                />
                                            </div>
                                        </b-card-text>
                                    </b-card-body>
                                </b-card>     
                                <button type="submit" class="btn btn-primary btn-block w-100">
                                    Carregar Logo Pequeno
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-xl-4 col-lg-6">
            <div class="card">
                <div class="card-body">
                    <div class="col-lg-12">
                        <h4 class="mb-3 header-title text-center">Atualizar Favicon</h4>
                        <div class="row justify-content-center">
                            <form
                                class="text-center"
                                enctype="multipart/form-data"
                            >
                                <b-card no-body :img-src="require('@/assets/images/small/img-1.jpg')" class="mb-0" img-alt="Card image" img-top>
                                    <b-card-body class="mb-0" style="padding-bottom: 0px">
                                        <b-card-title>
                                            <h5 class="card-title">Card title</h5>
                                        </b-card-title>
                                        <b-card-text>                                        
                                            <div class="upload-options">
                                                <label for="banner_image" class="btn">
                                                    <i class="mdi mdi-camera"></i> Carregar Favicon
                                                    <br />
                                                    <small>(300 X 300)</small>
                                                </label>
                                                <input
                                                    id="banner_image"
                                                    style="visibility: hidden"
                                                    type="file"
                                                    class="image-upload"
                                                    name="banner_image"
                                                    accept="image/*"
                                                />
                                            </div>
                                        </b-card-text>
                                    </b-card-body>
                                </b-card>    
                                <button type="submit" class="btn btn-primary btn-block w-100">
                                    Carregar Favicon
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-xl-4 col-lg-6">
            <div class="card">
                <div class="card-body">
                    <div class="col-lg-12">
                        <h4 class="mb-3 header-title text-center">Atualizar Banner do Login</h4>
                        <div class="row justify-content-center">
                            <form
                                class="text-center"
                                enctype="multipart/form-data"
                            >
                                <b-card no-body :img-src="require('@/assets/images/small/img-1.jpg')" class="mb-0" img-alt="Card image" img-top>
                                    <b-card-body class="mb-0" style="padding-bottom: 0px">
                                        <b-card-title>
                                            <h5 class="card-title">Card title</h5>
                                        </b-card-title>
                                        <b-card-text>                                        
                                            <div class="upload-options">
                                                <label for="banner_image" class="btn">
                                                    <i class="mdi mdi-camera"></i> Carregar Banner do
                                                    Lgin <br />
                                                    <small>(900 X 900)</small>
                                                </label>
                                                <input
                                                    id="banner_image"
                                                    style="visibility: hidden"
                                                    type="file"
                                                    class="image-upload"
                                                    name="banner_image"
                                                    accept="image/*"
                                                />
                                            </div>
                                        </b-card-text>
                                    </b-card-body>
                                </b-card>  
                                <button type="submit" class="btn btn-primary btn-block w-100">
                                    Carregar Banner do Login
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>